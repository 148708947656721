
















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ChatService from '@/services/ChatService'
import FormatDate from '@/helpers/FormatDate'
import { groupchatRef } from '@/firebase'
import { EListSort } from '@/models/Setting/Enum'
import LoadingChatList from '@/components/Loading/LoadingChatList.vue' 
import LoadingUserList from '@/components/Loading/LoadingUserList.vue'
import ManageMemberGroupChat from '@/views/Chat/ManageMemberGroupChat/index.vue'
import store from '@/store'
import Setting from '@/store/modules/Setting'
import { getModule } from 'vuex-module-decorators'
import PreviewText from '@/helpers/PreviewText'
import { EUserStatus } from '@/models'
import router from '@/router'
import FooterMobile from '@/components/FooterMobile.vue'
import GroupChatService from '@/services/GroupChatService'
const SettingModule = getModule(Setting, store)

interface IDropDown {
  id: number
  value: any
  text: string
  suffix?: string
}

@Component({ components: { LoadingChatList, LoadingUserList, ManageMemberGroupChat, FooterMobile } })
export default class ChatList extends Vue {
  private groupChats: any[] = []
  private page: number = 1
  private isLoading: boolean = true
  private isShowLoadMore: boolean = false
  private sortOptions: IDropDown[] = []
  private sortSelected: any = {}
  private avatarDefault = require('@/assets/images/avatar-default.png')
  private ATTACH_FILE: string = 'ATTACH_FILE'
  private MESSAGE_DELETED: string = 'MESSAGE_DELETED'
  private LEAVE_GROUP_CHAT: string = 'LEAVE_GROUP_CHAT'
  private LEAVE_SYSTEM: string = 'LEAVE_SYSTEM'
  private unsubscribe: any 
  public checkAdmin: boolean =
    this.$store.state.userInfo.user.profile.is_admin_company 
  private planChatModal: any[] = [];
  created() {
    this.initSortDropdown()
    this.handleRealTimeGorupChat()
    this.getPlan();
  }

  getPlan() {
    GroupChatService.getPlanCompany()
      .then((res) => {
        if (res.status === 200) {
          this.planChatModal = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }


  clickShowModal(number: number) {
    if (number == 4 || number == 5) {
      this.$bvModal.show("modal-show-err-email-list");
    } else {this.$bvModal.show('modal-confirm-list')}
  }


  destroyed() {
    this.unsubscribe()
  }

  @Watch('sortSelected')
  watchSortChange() {
    SettingModule.CHANGE_SORT_CHAT_LIST(this.sortSelected.id)
    setTimeout(() => {
      this.getGroupChat()
    }, 10)
  }

  initSortDropdown() {
    const data = [
      {
        id: EListSort.UPDATE_DESC,
        value: {
          order_by: 'updated_at',
          sorted_by: 'desc'
        },
        text:
          this.$t('common.dropdown_sort.sort_date_time') +
          '' +
          this.$t('common.dropdown_sort.desc')
      },
      {
        id: EListSort.UPDATE_ASC,
        value: {
          order_by: 'updated_at',
          sorted_by: 'asc'
        },
        text:
          this.$t('common.dropdown_sort.sort_date_time') +
          '' +
          this.$t('common.dropdown_sort.asc')
      },
      {
        id: EListSort.TITLE_DESC,
        value: {
          order_by: 'name',
          sorted_by: 'desc'
        },
        text:
          this.$t('common.dropdown_sort.sort_title_name') +
          '' +
          this.$t('common.dropdown_sort.desc')
      },
      {
        id: EListSort.TITLE_ASC,
        value: {
          order_by: 'name',
          sorted_by: 'asc'
        },
        text:
          this.$t('common.dropdown_sort.sort_title_name') +
          '' +
          this.$t('common.dropdown_sort.asc')
      }
    ]
    this.sortOptions = data
    this.sortSelected = data[this.$store.state.setting.user_sort.chat_list]
  }

  filterGroupChat(index: number) {
    this.sortSelected = this.sortOptions[index]
    this.page = 1
    this.groupChats = []
    this.getGroupChat()
  }

  @Watch('$store.state.userInfo.user.info.id')
  handleRealTimeGorupChat() {
    let trackingRealtime = true
    const userId = this.$store.state.userInfo.user.info.id
    if (!userId) return
    const start = this.moment()
      .subtract(1, 'm')
      .toDate()
    this.unsubscribe = groupchatRef
      .where('userIds', 'array-contains', userId)
      .where('updatedAt', '>', start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false
        } else {
          this.getGroupChat()
        }
      })
  }

  getChatBoxAvatar(group: any): any[] {
    return group.user_group_chat.slice(0, 4)
  }

  async getGroupChat(loadMore?: boolean) {
    let dataSearch: any = {
      isGetUnRead: 0,
      orderBy: this.sortSelected.value.order_by,
      sortedBy: this.sortSelected.value.sorted_by,
      limit: this.$store.state.setting.setting.setting_display
        .number_item_lists,
      page: this.page
    }

    this.isLoading = true
    await ChatService.getGroupChats(dataSearch)
      .then((res: any) => {
        if (res.status === 200) {
          this.isShowLoadMore = this.page < res.data.last_page ? true : false
          this.groupChats = loadMore
            ? [...this.groupChats, ...res.data.data]
            : res.data.data
        }
      })
      .catch(err => {
        console.log(err)
      })
    this.isLoading = false
  }

  loadMoreUnreadGroupChat() {
    this.page++
    this.getGroupChat(true)
  }

  markReadGroupChat(index: number) {
    let groupChatMarkRead = this.groupChats[index]
    let data: any = {
      group_chat_id: groupChatMarkRead.id,
      count_message_not_seen: 0
    }

    ChatService.markReadGroupChat(data)
      .then(res => {
        if (res.status === 200) {
          groupChatMarkRead.count_message_not_seen = 0
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  goToGroupChatDetailRoute(group: any) {
    if (group.can_chat == 0) {
      let params = { groupChatId:  group.id}
      this.$router.push(
        {
        name: 'chat-detail',
        params
      }
      )
    } else {
        this.$bvModal.show(
                  "modal-show-err-list"
                  // "create-group-chat-fail-modal-by-check-plan"
                );
      // this.$bvModal.show('create-group-chat-fail-modal-by-check-plan')
    }
  
  
  }

  groupChatLastMess(keyCode: string) {
    if (!keyCode) return ''
    const text = keyCode.split(',')[0]
    switch (text) {
      case this.ATTACH_FILE:
        return this.$t('common.message.chat_no_content')
      case this.MESSAGE_DELETED:
        return this.$t('chat.list.msg.msg_deleted')
      case this.LEAVE_GROUP_CHAT:
        return this.$t('chat.list.msg.leave_group_chat', {
          name: keyCode.split(',')[1]
        })
      case this.LEAVE_SYSTEM:
        return this.$t('chat.list.msg.leave_system', {
          name: keyCode.split(',')[1]
        })
      default:
        return keyCode
    }
  }

  handleSender(sender: any) {
    if (!sender) return { name: '', text: '' }
    let text = this.hanldeUserStatus(sender.name, sender.user_active_status)
    const preview = PreviewText.covertToPreviewText(text)
    return {
      name: text,
      text: preview.text
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  formatDateWithDays(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  handleSetGroupChatName(group: any) {
    if (!group.only_user) return group.name

    if (!group.only_user && !group.group_avatar.length) return group.name

    let groupChatName = group.user_group_chat

    if (group.only_user && group.user_leave_chat) {
      groupChatName = [
        {
          name: this.hanldeUserStatus(
            group.user_leave_chat.nickname,
            group.user_leave_chat.leaveType
          )
        }
      ]
    }

    if (!groupChatName) return ''
    let groupName =
      (groupChatName.length > 2 ? groupChatName.slice(0, 2) : groupChatName)
        .map((item: any) => item.name)
        .join(`${this.$t('common.suffix.san')}${this.$t('chat.comma')}`) +
      this.$t('common.suffix.san')
    let otherUserCount = groupChatName.length > 2 ? groupChatName.length - 2 : 0
    let groupNameFull =
      String(groupName) +
      (otherUserCount > 0
        ? this.$t('chat.chat_group_name_with', {
            other_user_count: otherUserCount
          })
        : '') +
      this.$t('chat.chat_with')

    return groupNameFull
  }
}
