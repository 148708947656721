



















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LoadingUserList extends Vue {}
